import React, { useEffect } from 'react';
// import { PageLayout } from '../modules/PageLayout';

const OnlyfansRedirect = (props) => {
  const { pageContext } = props;

  useEffect(() => {
    // redirect on page load
    window.location.replace(pageContext.data.Onlyfans);
  }, []);

  return null
  // if you want a full page instead
  // return <PageLayout {...props} />
};

export default OnlyfansRedirect;
